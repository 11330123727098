<template>
  <v-container fluid>
    <v-row>
      <v-col> this is the news page. </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'NewsPage',
};
</script>

<style></style>
